import { makeAutoObservable, autorun, runInAction, action, observable, makeObservable, computed } from "mobx"
import { evalCondition, browseObject, evalExpr } from '../common'
const DEFAULT_ACTIVE_RECORD = ['form', 'html']
import {StateAttrs} from './StateAttrs'
import {NewCalculatedValue} from './NewCalculatedValue'

class CalculatedValue {
    field = false
    value = null
    inputs = {}
    loading = true
    initialized = false

    constructor(field, record) {
        makeObservable(this, {
            value: observable,
            reload: action,
            toogleLoading: action,
            initialized: observable,
            // loading:observable,
        })
        this.field = field
        this.inputs = field.get_dependencies(record)
        // this.value = browseObject(record._values, field.expression)
        this.loading = false
        this.reload(record)


    }

    get_value() {
        return this.value
    }

    shouldReload(record) {
        let res = false
        for (let i in this.inputs) {
            if (this.inputs[i] !== record._values[i]) {
                res = true
            }
        }

        return res
    }

    toogleLoading() {
        this.loading = !this.loading
        
        // this.loading=true
    }

    async reload(record) {
        //Try to find de value on the record
        if (this.loading) {
            return
        }
        this.toogleLoading()
        let value = browseObject(record._values, this.field.expression)
        if (!value) {
            // alert("A network request will be made")
            value = await this.field.get_lookup_value(record)
        }
        
        runInAction(() => {
            
            
            this.value = value
            this.inputs = this.field.get_dependencies(record)
            this.toogleLoading()
            this.initialized = true;

        })

    }


}

class BinaryValue {
    field = false
    value = null
    loading = false
    

    constructor(field, record) {
        makeObservable(this, {
            //value:{data,type}
            value: observable,
            set_value:action,
            reload: action,
            toogleLoading: action
        })
        this.field = field
        this.value = null
        this.loading = false
        this.reload(record)


    }

    get_value() {
        return this.value
    }
    set_value(value){
        this.value = value
    }

    toogleLoading() {
        this.loading = !this.loading
        // this.loading=true
    }

    async reload(record) {
        //Try to find de value on the record
        this.toogleLoading()
        let value = record._values[this.field.name]
        if (!value) {
            value = await this.field.get_binary_value(record)
            
            
        }

        runInAction(() => {
            
            this.value = value ? {data:value}:false
            this.toogleLoading()

        })

    }


}

export class RecordGroup {
    records = []
    count = 0
    loading = true
    loadingInitialData = false
    current_page = 1
    next_new_id = 0
    _changed = false
    pagination_enabled = false
    default_page_size = 0
    page_size_options = []
    limit = null
    show_records_count = false


    get o2m_fields() {
        return this.screen.fields.filter(function (field) { return (field.type == 'one2many' && field.initialized === true) })
    }
    get page_qty() {
        return Math.ceil((this.count && this.limit) ? this.count / this.limit : 0)
    }


    constructor(connection, attrs, screen) {
        makeObservable(this, {
            records: observable,
            count: observable,
            loadData: action,
            setInitialLoading: action,
            setLoading: action,
            loadingInitialData: observable,
            loading: observable,
            addRecord: action,
            clearRecords:action,
            setChanged: action,
            removeRecords: action,
            replaceRecord: action,
            o2m_fields: computed,
            nextPage: action,
            prevPage: action,
            limit: observable,
            current_page: observable,
            setCurrentPage: action,
            pagination_enabled: observable,
            show_records_count: observable
        })
        this.connection = connection
        this.screen = screen
        this.pagination_enabled = attrs.pagination_enabled
        if (this.pagination_enabled) {
            this.default_page_size = attrs.default_page_size
            this.limit = attrs.default_page_size
            this.page_size_options = attrs.page_size_options
        }
        this.show_records_count = this.pagination_enabled ? true : attrs.show_records_count




    }

    get_record_by_index(index) {
        return this.records[index]
    }
    get_record_by_id(id) {
        return this.records.find(rec => rec.id === id)
    }

    setChanged(value) {
        this._changed = value
    }

    /**
    * Next New Id
    * @return Returns the next id for unsaved records(always negative) ""
    */
    get_next_new_id() {
        this.next_new_id -= 1
        return this.next_new_id
    }



    /**
     * Add New Record
     * @param {Object}  {value: Values for the new record. 
     * If undefined, defaults will be used,index:Position of the group to add the record }.
     
     * @return Record added ""
     */
    addRecord({ values, index }) {
        const i = index ? index : this.records.length
        if (!values) {
            values = {}
        }
        
        const record = new Record(values, this.screen, this)
        if (!Object.keys(values).length) {
            record.setDefaultValues()
        }
        this.records.splice(i, 0, record)

        return record
    }
    /**
     * Remove all records from group
     * @return {void}
     */
     clearRecords() {
        this.records = []
        this.next_new_id = 0
        this.count = 0
        

    }

    /**
     * Remove records from group
     * @param {array}  records to remove
     * @return {void}
     */
    removeRecords(records) {

        records.forEach(function (record) {
            this.records.splice(this.records.indexOf(record), 1)
        }.bind(this))
        if(this.screen.selected_records){
            this.screen.set_selected_record([])
        }
        

    }

    /**
     * Replace a record for other. Used on save executions
     * @param {old_record} Old Record  
     * @param {new_record} New Record
     * @return {void}
     */
    replaceRecord(old_record, new_record) {
        new_record.group = this;
        this.records[this.records.indexOf(old_record)] = new_record


    }
    setInitialLoading(value) {
        this.loadingInitialData = value
    }
    setLoading(value) {
        this.loading = value
    }


    /**
     * Move to next page of data.
     * @return {void}
     */
    setLimit(value) {

        this.limit = value
        //HERE => check set limit and reset page number to 0. Maybe is the run in action miss on the load data
        this.loadData(false, true)


    }

    /**
     * Move to next page of data.
     * @return {void}
     */
    nextPage() {
        const next_page = this.current_page + 1
        if (next_page > this.page_qty) {
            return
        }
        this.current_page = this.current_page + 1
        this.loadData()


    }
    /**
     * Move to prev page of data.
     * @return {void}
     */
    prevPage() {
        const prev_page = this.current_page - 1
        if (prev_page < 1) {
            return
        }
        this.current_page = this.current_page - 1
        this.loadData()

    }

    async getRecordsCount() {

        if (this.connection.status) {
            const abortController = new AbortController();
            let count = 0
            let args = {
                view_id: this.screen.id,
                search: this.screen.current_search,
                order: this.screen.order,
                count: true
            }


            if (!this.screen.headless) {
                args['action_id'] = this.screen.action_id ? this.screen.action_id : ""
                args['action_params'] = this.screen.action_params
            }

            count = await this.connection.dispatch('GET', '/data/get', args, false, false, false, abortController)

            runInAction(() => {

                this.count = count

            })




        }

    }

    setCurrentPage(value) {
        this.current_page = value
    }


    async loadData(force_count = false, reset_page = false, extra_args = {}) {

        if (!this.loadingInitialData && this.connection.status) {
            const abortController = new AbortController();
            let new_data = []
            this.loading = true

            let args = {
                view_id: this.screen.id,
                search: this.screen.current_search,
                order: this.screen.order,
            }
            if (this.pagination_enabled) {
                let current_page = this.current_page - 1
                args['limit'] = this.limit
                if (reset_page) {
                    current_page = 0
                    this.setCurrentPage(1)

                }
                args['offset'] = current_page * this.limit
            }

            if (!this.screen.headless) {
                args['action_id'] = this.screen.action_id ? this.screen.action_id : ""
                args['action_params'] = this.screen.action_params
            }

            // if (this.screen.type === 'graph') {

            //     args['graph'] = this.screen.graph
            // }
            args = { ...args, ...extra_args }


            
            //Prevent data fetching in form views if no id
            if ((this.screen.type === 'form' && !args.search.length && !args.action_params.length) || this.screen.data_origin === 'none') {
                
                runInAction(() => {
                    

                    if (DEFAULT_ACTIVE_RECORD.includes(this.screen.type)) {
                        const record = this.addRecord({})
                        this.screen.set_active_record(record)
                    }


                })

            }
            else {

                new_data = await this.connection.dispatch('GET', '/data/get', args, false, false, false, abortController)
                // if (!new_data.length) {

                // }
                runInAction(() => {
                    this.records = new_data.map(function (row) {

                        return new Record(row, this.screen, this)
                    }.bind(this))
                    if (DEFAULT_ACTIVE_RECORD.includes(this.screen.type)) {
                        this.screen.set_active_record(this.records[0])
                    }



                })

            }






            runInAction(() => {
                if (this.loadingInitialData) {
                    this.loadingInitialData = false;
                    //count records after the initial loading
                    
                    if (this.show_records_count) {
                        this.getRecordsCount()
                    }


                }
                this.loading = false;


            })
            // if(force_count){
            //     console.log("getting record count from")

            //REmoved force: Always count on data fetch.
            if (this.show_records_count) {
                this.getRecordsCount()
            }

            // }




        }

    }


}

export class Record {
    id = -1
    _values = {}
    _changed = {}
    _errors = {}
    state_attrs = {}
    conditional_styles = {}
    group = false
    field_in_edition = false


    get backend_values() {
        let values = {}
        for (let v in this._values) {
            if (this.isField(v)) {
                values[v] = this._values[v]

            }
        }
        let fnames = Object.keys(values)
        this.listener_fields.forEach(function (lf) {
            if (fnames.includes(lf.name)) {
                // values[lf.name] = this._values[lf.name].get_value()
                delete values[lf.name]
            }
        }.bind(this))
        return values
    }

    get required_fields() {
        return Object.keys(this.state_attrs).filter(function (fname) {
            return this.state_attrs[fname].required

        }.bind(this)).map(function (fname) {
            return this.screen.fields.find(f => f.name === fname)
        }.bind(this))
    }

    get listener_fields() {
        return this.screen.fields.filter(function (f) {
            return f.type === 'lookup'
        })
    }
    get binary_fields() {
        return this.screen.fields.filter(function (f) {
            return f.type === 'binary'
        })
    }
    get formula_fields() {
        return this.screen.fields.filter(function (f) {
            return f.type === 'formula'
        })
    }
    get parent_default_fields() {
        return this.screen.fields.filter(function (f) {
            if(f.default_value){
                return f.default_value.includes("_parent_record")
            }
            
        })
    }

    get parent_selected_records_fields(){
        return this.screen.fields.filter(function (f) {
            if(f.default_value){
                return f.default_value.includes("_parent_selected_records")
            }
            
        })
    }

    get mobile_record_title() {
        return evalExpr(this._values, this.screen.mobile_record_title).str || ""
    }



    constructor(attributes, screen, group) {
        makeObservable(this, {
            set_state_attrs: action,
            set_value: action,
            set_values: action,
            set_changed: action,
            set_conditional_styles:action,
            _values: observable,
            _changed: observable,
            _errors: observable,
            state_attrs: observable,
            conditional_styles:observable,
            field_in_edition:observable,
            setFieldInEdition:action,
            setDefaultValues: action,
            // validate:action,
            backend_values: computed,
            required_fields: computed,
            set_calculated_value: action
            // o2m_fields:computed,
        })

        const id = attributes.id ? attributes.id : group ? group.get_next_new_id() : -1
        this.id = id
        this._values = attributes
        this._changed = {}
        this._values['id'] = id
        this.screen = screen

        this.group = group
        this.state_attrs = {}
        this.field_in_edition = false
        this.set_state_attrs()
        this.set_conditional_styles()


    }

    setFieldInEdition(fname){
        this.field_in_edition = fname;
    }

    get_value(fname) {
        return this._values[fname]
    }

    isField(fname) {
        return this.screen.field_names.includes(fname)
    }

    on_change() {

        let values = {}
        let prms = []
        this.listener_fields.forEach(function (field) {

            if (this._values[field.name]) {
                if (!this._values[field.name].shouldReload(this)) {
                    return false
                }
                else {
                    
                    prms.push(this._values[field.name].reload(this))
                    this._changed[field.name] = true
                    
                }
            }
            else {
                values[field.name] = new CalculatedValue(field, this)
            }


        }.bind(this))

        if (Object.keys(values).length) {
            this.set_values(values)
        }
        return prms

    }
    set_calculated_value(field) {

        if (this._values[field.name]) {
            return this._values[field.name].get_value()
        }
        else {
            this._values[field.name] = new CalculatedValue(field, this)
        }

        return this._values[field.name].get_value()

    }

    set_new_calculated_value(field) {

        if (this._values[field.name]) {
            return this._values[field.name].get_value()
        }
        else {
            this._values[field.name] = new NewCalculatedValue(field, this)
        }

        return this._values[field.name].get_value()

    }
    set_binary_value(field) {
        if (this._values[field.name]) {
            return this._values[field.name].get_value()
        }
        else {
            this._values[field.name] = new BinaryValue(field, this)
        }

        return this._values[field.name].get_value()
    }

    set_changed(fnames) {
        let changed = false
        fnames.forEach(function (fname) {
            if (this.isField(fname)) {
                this._changed[fname] = true
                changed = true
            }

        }.bind(this))
        if (changed && this.group && !this.group._changed) {
            this.group.setChanged(changed)
        }
        let prms = this.on_change()
        // if (prms.length) {
        //     Promise.all(prms).then(function (p) {

        //         this.set_state_attrs()
        //     }.bind(this))
        // }
        // else {
        //     this.set_state_attrs()
        // }


    }

    set_value(fname, value) {
        this._values[fname] = value
        this.set_changed([fname])

    }
    set_values(values) {
        const fnames = []
        for (let fname in values) {
            this._values[fname] = values[fname]
            fnames.push(fname)

        }
        this.set_changed(fnames)
    }

    /**
* Get default values based on parent_record
* @param {Record} parent - Array of records. If undefined, the current screen selected records will be used
* @return {array} new (saved) parent records or false
*/
    get_parent_defaults(parent_record) {
        let values = {}
        if (!parent_record) {
            parent_record = this.screen.parent.active_record
        }
        
        if (parent_record) {

            this.parent_default_fields.forEach(function (f) {
                values[f.name] = browseObject(parent_record._values, f.default_value, false, this.screen.fields, parent_record._values)

            }.bind(this))
            
            
        }
        this.parent_selected_records_fields.forEach(function (f) {
            // values[f.name] = browseObject(parent_record._values, f.default_value, false, this.screen.fields, parent_record._values)
            values[f.name] = this.screen.parent_selected_records
            values[f.name] = browseObject({},f.default_value,false,this.screen.fields,{},this.screen.parent_selected_records)

        }.bind(this))
        return values
    }

    async setDefaultValues() {
        let values = await this.getDefaultValues()
        //REVIEW
        if (values[0].hasOwnProperty('id')) {
            this.id = values[0].id
        }
        values = { ...values[0], ...this.get_parent_defaults() }
        //TODO: Review _parent_record data => this works, but the approach is under revision


        this.set_values(values)
    }

    async getDefaultValues() {
        let args = { view_id: this.screen.id, search: {}, order: [] }
        const abortController = new AbortController();
        let default_values = await this.group.connection.dispatch('GET', '/view/default_values', args, false, false, false, abortController)
        return default_values


    }

    get_changed_values() {

        let cv = {}
        for (let field in this._changed) {
            cv[field] = this._values[field]
        }
        
        let fnames = Object.keys(cv)

        this.listener_fields.forEach(function (lf) {
            if (fnames.includes(lf.name)) {
                cv[lf.name] = this._values[lf.name].get_value()
            }
        }.bind(this))
        this.binary_fields.forEach(function (bf) {
            if (fnames.includes(bf.name)) {
                cv[bf.name] = this._values[bf.name].get_value()
            }
        }.bind(this))
        return cv
    }

    get_changed_childs_records() {
        let values = {}
        if (!this.group) {
            return {}
        }
        this.group.o2m_fields.filter(function (f) {
            return f.screen && f.screen.data._changed === true
        }).forEach(function (f) {
            values[f.name] = f.screen.data.records
        })

        return values


    }

    get_childs_records() {
        let values = {}
        this.group.o2m_fields.forEach(function (f) {
            values[f.name] = f.screen.data.records
        })

        return values
    }

    validate(required_fields) {
        

        let valid = true
        let _errors = {}
        if (!required_fields) {
            required_fields = this.required_fields
        }
        required_fields.forEach(function (field) {
            if (!field.has_value(this)) {
                valid = false
                _errors[field.name] = "required"
            }
        }.bind(this))
        this._errors = _errors;
        if (valid) {
            valid = this.validate_childs()
        }
        if (!valid) {
            this.screen.notifications.addSnack(
                { message: "Por favor, complete los campos requeridos.", level: 'error', timeout: 2000 }
            )
        }
        return valid
    }
    validate_childs() {
        let valid = true
        let child_values = this.get_changed_childs_records()
        for (let child in child_values) {
            child_values[child].forEach(function (rec) {
                if (!rec.validate()) {
                    valid = false
                }
            })
        }
        return valid
    }
    //All values except calculated
    get_raw_values(){
        let raw_values = {}
        let calculated_fnames = [...this.listener_fields.map(function(f){return f.name}), ...this.formula_fields.map(function(f){return f.name})
        ]
        
        for(let k in this._values){
            if(!calculated_fnames.includes(k)){
                raw_values[k] = this._values[k]
            }
        }
        return raw_values

    }
    get_all_values() {
        let values = { ...this._values }
        let fnames = Object.keys(values)

        //solve listener fields values
        this.listener_fields.forEach(function (lf) {
            
            if (fnames.includes(lf.name)) {
                
                values[lf.name] = this._values[lf.name].get_value()
                
            }
            else{
                
            }
        }.bind(this))
        this.binary_fields.forEach(function (bf) {
            if (fnames.includes(bf.name)) {
                values[bf.name] = this._values[bf.name].get_value()
            }
        }.bind(this))

        this.formula_fields.forEach(function (ff) {
            if (fnames.includes(ff.name)) {
                values[ff.name] = this._values[ff.name].get_value()
            }
        }.bind(this))

        let child_values = this.get_childs_records()
        for (let child in child_values) {
            values[child] = child_values[child].map(function (c) {
                return c.get_all_values()
            })
        }
        return values;
    }
    async save(action) {

        if (!this.validate()) {
            return false
        }
        const abortController = new AbortController();
        const path = action.custom_path ? action.custom_path:'/data/save'
        let args = {}
        args['action_id'] = action.id
        let values = {}
        // get every changed field in the record
        let vals = this.get_changed_values()
        // get every record on childs (o2m fields)
        //TODO: Improve to send only changed lines, requires backend changes to accept
        //modifiers
        let child_values = this.get_changed_childs_records()


        for (let child in child_values) {

            vals[child] = child_values[child].map(function (c) {
                return c.backend_values
            })


        }

        // Always add id to the values
        vals.id = this.id

        values[this.id] = vals

        args['values'] = values

        let res = await this.screen.connection.dispatch('POST', path, args, false, false, false, abortController)
        return res
    }

    
    async set_state_attrs() {
        
        if (!this._values) {
            return false
        }

        // const state_attrs = await this.get_state_attrs()
        const state_attrs = {}
        
        this.screen.fields.forEach(function(field){
            state_attrs[field.name] = new StateAttrs(field, this)
        }.bind(this))
        runInAction(() => {
        
            this.state_attrs = state_attrs;

        })
        
       

    }

    get_style(fname){
        let style = {}
        if(this.conditional_styles && this.conditional_styles[fname]){
            style = this.conditional_styles[fname].get_value()
        }
        return style

    }

    async set_conditional_styles() {
        
        if (!this._values) {
            return false
        }

        // const state_attrs = await this.get_state_attrs()
        const conditional_styles = {}
        
        this.screen.conditional_style_fields.forEach(function(field){
            conditional_styles[field.name] = new NewCalculatedValue(field,this,field.conditional_style_dependencies,async (calc)=>{return await field.get_conditional_style(calc)})
            // conditional_styles[field.name].get_value()
        }.bind(this))
        
        runInAction(() => {
        
            this.conditional_styles = conditional_styles;

        })
        
       

    }






}


