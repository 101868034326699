import React from 'react';
import ReactTooltipLite from 'react-tooltip-lite';
import './tooltip.css';

function Tooltip(props){
	const { children, content, type } = props;
	const background = type === 'html' ? 'white':''

	return (
			<ReactTooltipLite
				background={background}
				
				tipContentClassName=" text-gray-800  text-xs font-medium"
				tipContentHover
				content={type==='standard' ? 
					content
					:
					<div dangerouslySetInnerHTML={{ __html: content }}></div>
				}
            >
				{children}
			</ReactTooltipLite>
		)
}

export default Tooltip;
