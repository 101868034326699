import React, { useState, useEffect, useContext } from 'react';
import Paper from '../../ui/surfaces/Paper.js'
import Field from '../../ui/fields/Field.js'
import FormDivider from '../../ui/fields/FormDivider.js'
import FormButton from '../../ui/buttons/FormButton.js'
import Tooltip from '../../ui/Tooltip';
import LoadingIndicator from '../../common/LoadingIndicator'
import { observer } from 'mobx-react-lite'
import { colord } from 'colord';
import ViewHeader from '../ViewHeader'
const baseFieldCont = "w-full col-span-4 "


const fieldColspan = {
    1: "md:col-span-1",
    2: "md:col-span-2",
    3: "md:col-span-3",
    4: "md:col-span-4"
}

const NO_FIELD_WIDGETS = ['divider', 'button']

const FormField = observer(function FormField(props) {
    const { field, record } = props;
    const state_attrs = field.get_state_attrs(record)

    /*
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [posX, setPosX] = useState(null);
    const [posY, setPosY] = useState(null);

    const openPalette = (e) => {
        const formSize = e.target.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect()
        setPosX(e.clientX - 170);
        setPosY(((Math.abs(formSize.y) + e.clientY) - 95));
        setOpenColorPicker(true)
    }
    */


    function renderSpecialWiget(field) {

        switch (field.type) {
            case 'button': {
                return <FormButton

                    onClick={
                        (e) => {
                            
                            field.action.execute([record], false)


                        }}
                    color={field.button_variant}
                    state_attrs={state_attrs}
                    style={field.style || {}}

                >{field.description}

                </FormButton>
            }
            // case 'divider': return <FormDivider invisible={props.active_record.getFieldAttr(field, 'invisible')} text={field.description} />
            case 'divider': return <FormDivider text={field.description} />
            default: return <></>
        }

    }

    return (
        <div style={{ display: state_attrs.invisible ? 'none' : '' }} key={field.name} className={baseFieldCont.concat(fieldColspan[field.colspan] || 4)}>


            {NO_FIELD_WIDGETS.includes(field.type) ?
                <>{renderSpecialWiget(field)}</>

                :
                field.tooltip ?
                    <Tooltip content={field.tooltip} type={field.tooltip_type}>
                        <Field
                            field={field}
                            record={record}
                            variant="outlined"
                        />
                    </Tooltip>
                    :
                    <Field
                        field={field}
                        record={record}
                        variant="outlined"
                    />
            }



        </div>
    )
});
// function FormView(props) {
const FormView = observer(function FormView(props) {


    const container_ref = React.createRef()
    const { screen } = props;
    const fields = screen.visible_fields;
    const record = screen.active_record
    const loading = screen.data.loading




    return (
        <Paper className="py-2 px-2 rounded" style={props.containerStyle}>

            {/* <div className="text-xl px-6 py-1 text-gray-800">{screen.title}</div> */}
            <ViewHeader
                 screen={screen}
                 title_classname="text-xl px-6 py-1 text-gray-800"
                 hide_toolbar={props.hide_toolbar}
                 isSpreadsheet={false}
            />
            <div ref={container_ref} className="flex-grow grid grid-cols-4 w-full row-gap-5 col-gap-4 py-4 px-4">
                {fields && record ?
                    <>
                        {fields.map((field, index) => (

                            <FormField
                                key={field.id}
                                field={field}
                                record={record} />

                        ))}
                    </>
                    :
                    ""
                }



            </div>
            <LoadingIndicator
                ref={container_ref}
                show={loading}
                
            />
        </Paper>


    )

});

export default FormView;